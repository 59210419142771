import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import Moment from 'react-moment';
import { config } from '../config';

function Profiledetail(props) {

    if(!props.profile) return "";

    return (
        <div className="card w-100 shadow-xss rounded-xxl border-0 mb-3 pt-3">
            { (props.profile.bio || props.isCurrentUser) &&
            <div className="card-body d-block p-4 pt-1 pb-3">
                <h4 className="fw-700 mb-3 font-xsss text-grey-900">Sobre mim</h4>
                <p className="fw-500 text-grey-500 lh-24 font-xssss mb-0">{ props.profile.bio }</p>
                { props.isCurrentUser && <p><Link to={'/account'} className="fw-600 ms-auto font-xssss text-success"><i className="feather-edit me-1"></i> Editar</Link></p> }
            </div>
            }
            { config.FEATURES_PREVIEW_ENABLED &&
            <div className="card-body border-top-xs d-flex">
                <i className="feather-lock text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-700 text-grey-900 font-xssss mt-0">Private <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">What's up, how are you?</span></h4>
            </div>
            }

            <div className="card-body d-flex pt-0">
                <i className="feather-briefcase text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-700 text-grey-900 font-xssss mt-0">{ props.profile.jobTitle || '-' } <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{ props.profile.company?.name || '-' }</span></h4>
            </div>
            { props.profile.hireDate && !props.profile.terminationDate &&
            <div className="card-body d-flex pt-0">
                <i className="feather-user-check text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1"><Moment fromNow ago>{ props.profile.hireDate }</Moment> <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Antiguidade</span></h4>
            </div>
            }
            { props.profile.birthdate &&
            <div className="card-body d-flex pt-0">
                <i className="feather-gift text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1"><Moment format='DD [de] MMMM'>{props.profile.birthdate}</Moment> <span className="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Aniversário</span></h4>
            </div>
            }
            <div className="card-body d-flex pt-0">
                <i className="feather-mail text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1">{props.profile.email || '-' }</h4>
            </div>
            { props.profile.phone &&
            <div className="card-body d-flex pt-0">
                <i className="feather-phone text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1">{props.profile.phone || '-' }</h4>
            </div>
            }
            { props.profile.mobile &&
            <div className="card-body d-flex pt-0">
                <i className="ti-mobile text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1">
                    {props.profile.mobile}<br />
                    <span className="mt-1">{props.profile.mobilePersonal}</span>
                    {!props.profile.mobile && !props.profile.mobilePersonal && '-' }
                </h4>
            </div>
            }
            { config.FEATURES_PREVIEW_ENABLED &&
            <div className="card-body d-flex pt-0">
                <i className="feather-users text-grey-500 me-3 font-lg"></i>
                <h4 className="fw-700 text-grey-900 font-xssss mt-1">Genarel Group</h4>
            </div>
            }
        </div>
    );
}

export default Profiledetail;