import React, { Fragment, useRef, useEffect, useState } from "react";
import { Link } from 'react-router-dom';

import { useQuery, gql, useMutation } from '@apollo/client';
import LoadingOverlayWrapper from 'react-loading-overlay-ts';

import { toast } from "react-toastify";

import { useForm } from "react-hook-form";

import QRCode from "react-qr-code";

import Header from '../components/Header';
import Leftnav from '../components/Leftnav';
import Appfooter from '../components/Appfooter';
import moment from "moment";

const GET_VIEWER_QUERY = gql`
    query GetViewer {
        Viewer {
            id
            username
            email
            isEmailVerified
            name
            picture
            employee {
                id
                fullName
                name
                jobTitle
                phone
                mobile
                mobilePersonal
                email
                bio
                birthdate
                picture
                company {
                    id
                    name
                }
                department {
                    id
                    name
                }
                createdDate
                lastModifiedDate
            }
        }
    }
`;

const EmployeeCardQRCode = () => {

    const { loading, error, data, refetch } = useQuery(GET_VIEWER_QUERY);

    const [qrCodeValue, setQrCodeValue] = useState(null)
    const [isQrCodeValid, setIsQrCodeValid] = useState(null)

    useEffect(() => {
        let qrCodeValueData = "BEGIN:VCARD\nVERSION:3.0\n"

        if(data?.Viewer?.employee?.name && data?.Viewer?.employee?.name.trim().length > 0) {
            var firstName = data?.Viewer?.employee?.name.trim().split(' ').slice(0, 1).join(' ');
            var lastName = data?.Viewer?.employee?.name.trim().split(' ').slice(-1).join(' ');

            if(data?.Viewer?.employee?.name.trim().split(' ').length == 1) {
                qrCodeValueData += `N:;${firstName};;;\n`
            } else {
                qrCodeValueData += `N:${lastName};${firstName};;;\n`
            }
            qrCodeValueData += `FN:${data?.Viewer?.employee?.name.trim()}\n`
        } else {
            setIsQrCodeValid(false)
            return;
        }
        
        //qrCodeValueData += "ADR;TYPE=WORK:;;Estrada Nacional X;Alcobaça;;0000-000;Portugal\n"
    
        if(data?.Viewer?.employee?.company?.name && data?.Viewer?.employee?.company?.name.trim().length > 0 && data?.Viewer?.employee?.department?.name && data?.Viewer?.employee?.department?.name.trim().length > 0) {
            qrCodeValueData += `ORG:${data?.Viewer?.employee?.company?.name};${data?.Viewer?.employee?.department?.name}\n`
        }

        if(data?.Viewer?.employee?.jobTitle && data?.Viewer?.employee?.jobTitle.trim().length > 0) {
            qrCodeValueData += `TITLE:${data?.Viewer?.employee?.jobTitle.trim()}\n`
        }
        
        if(data?.Viewer?.employee?.mobile && data?.Viewer?.employee?.mobile.trim().length > 0) {
            qrCodeValueData += `TEL;WORK:${data?.Viewer?.employee?.mobile.trim()}\n`
        }

        if(data?.Viewer?.employee?.mobile && data?.Viewer?.employee?.mobilePersonal.trim().length > 0) {
            qrCodeValueData += `TEL;CELL:${data?.Viewer?.employee?.mobilePersonal.trim()}\n`
        }

        
        if(data?.Viewer?.employee?.phone && data?.Viewer?.employee?.phone.trim().length > 0) {
            qrCodeValueData += `TEL;WORK:${data?.Viewer?.employee?.phone.trim()}\n`
        }

        /*
        if(response.data.url && response.data.url.trim().length > 0) {
        qrCodeValueData += `URL;WORK:${response.data.url.trim()}\n`
        }
*/
        if(data?.Viewer?.employee?.email && data?.Viewer?.employee?.email.trim().length > 0) {
            qrCodeValueData += `EMAIL;WORK:${data?.Viewer?.employee?.email.trim()}\n`
        }

        const lastUpdatedDate = data?.Viewer?.employee?.lastModifiedDate ? moment(data?.Viewer?.employee?.lastModifiedDate).format('DD-MM-YYYY') : moment(data?.Viewer?.employee?.createdDate).format('DD-MM-YYYY')
        qrCodeValueData += `REV:${lastUpdatedDate}\nEND:VCARD`

        console.log(qrCodeValueData)

        setQrCodeValue(qrCodeValueData)
        setIsQrCodeValid(true)
    }, [data])

    return (
        <Fragment> 
            <Header />
            <Leftnav />

            <div className="main-content bg-lightgreen theme-dark-bg">
                { loading && <LoadingOverlayWrapper/>}
                <div className="middle-sidebar-bottom">
                    <div className="middle-sidebar-left">
                        <div className="middle-wrap">
                            <div className="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                                <div className="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                                    <Link to="/feed" className="d-inline-block mt-2"><i className="ti-arrow-left font-sm text-white"></i></Link>
                                    <h4 className="font-xs text-white fw-600 ms-4 mb-0 mt-2">Cartão de Visita</h4>
                                </div>
                                <div className="card-body p-lg-5 p-4 w-100 border-0 ">
                                    { isQrCodeValid && qrCodeValue && <>
                                    <div className="row">
                                        <QRCode value={qrCodeValue} fgColor="#4cd964" size={256} />
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mt-4 text-center">
                                            <Link to={'/account'}><i class="feather-edit me-1"></i> Editar Contacto</Link>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-12 mt-4">
                                            <div className="card alert-success p-3">
                                                Este código QR é o teu cartão de visita eletrónio ou virtual e contém os teus dados de contacto.
                                                <br />
                                                O código QR pode ser lido por qualquer telemóvel. Após a leitura, os detalhes do cartão de visita são mostrados ao utilizador, que pode guardá-los no seu dispositivo.
                                            </div>
                                        </div>
                                    </div>
                                    </> }

                                    { !loading && !isQrCodeValid &&
                                    <div className="row">
                                        <div className="col-lg-12 mt-4">
                                            <div className="card alert-warning p-3 text-center">
                                                O teu cartão de visita ainda não se encontra disponível.
                                            </div>
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                </div>            
            </div>
            
            <Appfooter /> 
        </Fragment>
    );
}

export default EmployeeCardQRCode;